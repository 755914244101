import clientSide from '~/helpers/clientSide';
import { Decisions } from '~/types/providers';

const checkUnservRedirectEnabled = (currentPage: string): boolean => {
  let unservRedirectEnabled = false;
  if (clientSide) {
    window?.cohesion('preamp:decisionsReceived', function (decision: Decisions) {
      const placement =
        decision.placements.find(({ placementName }) => placementName === 'editable-loader-config') || {};
      if ('metadata' in placement) {
        unservRedirectEnabled = placement?.metadata[currentPage]?.unservRedirectEnabled === 'true';
      }
    });
  }
  return unservRedirectEnabled;
};

export default checkUnservRedirectEnabled;
