import {
  ActionTypes,
  SetContactAction,
  Contact,
  CreditResponse,
  SetOneTimePasswordAction,
  OneTimePassword,
} from '~/types/contact-credit';
import { ReduxState } from '~/types/redux';
import { Dispatch } from 'redux';
import { RecordUnknown } from '~/types/common';

interface SetCreditValues {
  type: ActionTypes.SET_CREDIT;
  credit: RecordUnknown;
}

export const setContact = (contact: Contact): SetContactAction => {
  return { type: ActionTypes.SET_CONTACT, contact };
};

export const setCredit = (credit: CreditResponse) => {
  return (dispatch: Dispatch, getState: () => ReduxState): SetCreditValues => {
    const { products } = getState();
    const plan = products?.selected?.internet;

    const creditData = {
      ...credit,
      billingSummary: {
        ...credit.billingSummary,
        lineItems: (() => {
          const filteredLineItems = (credit?.billingSummary?.lineItems || [])
            .map((lineItem) => {
              const { category, unit, name, displayName } = lineItem;
              const isVisibile = category === 'Product' && unit === 'MONTHLY';
              const planMatch = plan?.name === name || plan?.name === displayName;

              if (planMatch || !isVisibile) {
                return undefined;
              }

              return lineItem;
            })
            .filter(Boolean);

          return filteredLineItems;
        })(),
        routerType: credit?.billingSummary?.lineItems.filter(({ category }) => category === 'Eero') || [],
        plan,
      },
    };

    if (creditData.identityVerification) {
      creditData.identityVerification = creditData.identityVerification.map((data) => {
        return {
          ...data,
          answerChoices: data.answerChoices.map((answer) => ({
            label: answer.text,
            value: answer.number,
          })),
        };
      });
    }

    dispatch({ type: ActionTypes.SET_CREDIT, credit: creditData });

    return { type: ActionTypes.SET_CREDIT, credit: creditData };
  };
};

export const setOneTimePassword = (otp: OneTimePassword): SetOneTimePasswordAction => {
  return { type: ActionTypes.SET_ONETIMEPASSWORD, otp };
};
