import { Actions, ActionTypes } from '~/types/billing';
import { Price } from '~/types/products';

export const setPaperBill = (paperBill: boolean): Actions => {
  return { type: ActionTypes.SET_PAPER_BILL, paperBill };
};

export const setPaperBillFee = (paperBillFee: Price): Actions => {
  return { type: ActionTypes.SET_PAPER_BILL_FEE, paperBillFee };
};

export const setAutoPay = (key: string, value: boolean | string): Actions => {
  return { type: ActionTypes.SET_AUTO_PAY, key, value };
};

export const resetAutoPay = (): Actions => {
  return { type: ActionTypes.RESET_AUTO_PAY };
};

export const setUpfrontCharge = (key: string, value: boolean | string): Actions => {
  return { type: ActionTypes.SET_UPFRONT_CHARGE, key, value };
};
