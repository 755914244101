import { EXTERNAL_PAGE_ROUTES } from "~/constants/external-pages"

export const routeToExternal = (page: string, env?: string, controlNumber?:string): void => {
    if (window?.location?.href) {
        page != EXTERNAL_PAGE_ROUTES.PAL_NEEDED_PROD && page != EXTERNAL_PAGE_ROUTES.PAL_NEEDED_QA && page != EXTERNAL_PAGE_ROUTES.WHERES_MY_FIBER ?
        (window.location.href = page) : appendEnvControlNumber(page, env, controlNumber)
    }
}

export const appendEnvControlNumber =(page: string, env: string, controlNumber: string): void => {
    if (window?.location?.href ){
        window.location.href = page + `&e=${env}&c=${controlNumber}`
    }
}
