export const isLessThanTwelveMonths = (availableDate:string):boolean => {
  if(!availableDate) return false;
  const futureDate = Date.parse(availableDate);
  const now = new Date();

  // Subtract 1 day, add 1 year.
  let futureThreshold = new Date(now).setDate(now.getDate() - 1);
  futureThreshold = new Date(futureThreshold).setFullYear(now.getFullYear() + 1);

  if(futureDate < futureThreshold) {
    return true;
  }

  return false;
}

export const isFutureFiber = (fiberBuildOutStatus: string, futureServiceDate: string):boolean => {
  if(!fiberBuildOutStatus || !futureServiceDate) return false;

  // FTR team currently doesn't have dates coming through when fiberBuildOutStatus === FUTURE,
  // but once added this redirect should work as intended.
  const isFutureOrPending = fiberBuildOutStatus === 'FUTURE' || fiberBuildOutStatus === 'PENDING';
  const isWithinTimeFrame = isLessThanTwelveMonths(futureServiceDate);

  if(isFutureOrPending && isWithinTimeFrame) return true;

  return false;
}
