import { useState, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export const useRecaptchaToken = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const getToken = async () => {
      if (executeRecaptcha) {
        try {
          const result = await executeRecaptcha();
          setToken(result);
        } catch (error) {
          console.error('Failed to execute reCAPTCHA:', error);
          setToken(null);
        }
      }
    };
    getToken();
  }, [executeRecaptcha]);

  return token;
};
