import Cookies from 'js-cookie';
import { ParsedUrlQuery } from 'querystring';
import { EXTERNAL_PAGE_ROUTES } from '~/constants/external-pages';
import { routeToExternal } from './routeToExternal';
import { trackEvent } from './tracking';

// returns true if nurture campaign query param is found in query string
export const isNurtureCampaign = (query: ParsedUrlQuery): boolean => {
  const landingPageQueryString = Cookies.get('queryString');

  // checks cookie from landing page
  const matchedQueryString = Boolean(
    ['utm_market=launch', 'utm_phase=launch'].find((queryString) => landingPageQueryString?.includes(queryString))
  );
  // checks url once in cart
  const matchUtmParams = Boolean([query?.utm_market, query?.utm_phase].find((key) => key === 'launch'));
      
  return matchedQueryString || matchUtmParams;
};

export const nurtureRedirect = (): void => {
  trackEvent({
    action: 'elementViewed',
    data: {
      elementType: 'REDIRECT',
      location: 'ADDRESS CHECK',
      position: 'UNSERVICEABLE',
      text: 'NURTURE CAMPAIGN',
    },
  });
  
 routeToExternal(EXTERNAL_PAGE_ROUTES.UNSERVICEABLE_NURTURE_CAMPAIGN);
};

export default isNurtureCampaign;
