
export const EXTERNAL_PAGE_ROUTES = {
    UNSERVICEABLE_NURTURE_CAMPAIGN:
      'https://frontier.com/why-frontier/why-fiber-internet/fiber-expansion?icid=rv_expunserviceable',
    PAL_NEEDED_PROD: 'https://frontier.com/why-frontier/why-fiber-internet/fiber-expansion?icid=rv_mdupal',
    PAL_NEEDED_QA:'https://qat03.frontier.com/why-frontier/why-fiber-internet/fiber-expansion?icid=rv_mdupal',
    WHERES_MY_FIBER: 'https://frontier.com/why-frontier/why-fiber-internet/fiber-expansion?'
  };

  export const EXTERNAL_PAGE_ROUTE_VALUES = Object.values(EXTERNAL_PAGE_ROUTES);
